import { ComponentPropsWithRef, forwardRef, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { classNames } from '@common/utils/helpers'
import { useCombinedRefs } from '@common/utils/use-hooks'
import Image from 'next/image'

interface CheckBoxProps extends ComponentPropsWithRef<'input'> {
  className?: string | undefined
  name?: string
  logo?: any
  caption?: string | JSX.Element
  errorMessage?: string | undefined
  refRegister?: any
  isTableInput?: boolean
  disabled?: boolean
  primary?: boolean
  wrapperClassName?: string
  readOnly?: boolean
  backgroundColor?: string
  checked?: boolean
}

type CheckBoxTypes = PropsWithChildren<CheckBoxProps>

const CheckBoxWrapper = styled.div<{
  className?: string
  backgroundColor?: string
  disabled?: boolean
  readOnly?: boolean
  logo?: any
}>`
  display: flex;
  align-items: center;
  width: fit-content;

  .pq-checkbox {
    display: ${(props) => (props.logo ? 'flex' : 'block')};
    ${(props) => props.logo && 'align-items: center;'};
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    &.-primary {
      border-radius: 10px;
      border: solid 0px rgba(203, 208, 223, 0.3);
      outline: 0;
      padding-left: 16px;
      font-family: Poppins;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      color: #0d1331;
      cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
    }
  }
  .pq-checkbox-title {
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: #0d1331;
    padding-left: ${(props) => (props.logo ? 0 : '16px;')};
    white-space: nowrap;
    &.-disabled {
      opacity: 0.5;
    }
  }

  .pq-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: ${(props) => (props.disabled ? 'none' : 'pointer')} !important;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: ${(props) => (props.logo ? '50%' : 0)};
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    border: solid 2px rgba(203, 208, 223, 0.3);

    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : '#ffffff'};
    ${(props) => props.logo && 'transform: translateY(-50%);'};
  }

  .pq-checkbox input:focus ~ .checkmark,
  .pq-checkbox:hover input ~ .checkmark {
    ${(props) => !props.readOnly && 'border: solid 2px #3f6db4;'}
  }
  .pq-checkbox input:checked ~ .checkmark {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    border: solid 2px #3f6db4;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : '#ffffff'};
  }

  .pq-checkbox input:not(:checked) ~ .checkmark {
    border: solid 2px rgba(203, 208, 223, 0.3);
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .pq-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  .pq-checkbox.-disabled:hover input ~ .checkmark {
    ${(props) =>
      !props.readOnly && 'border: solid 2px rgba(203, 208, 223, 0.3);'}
  }
  .pq-checkbox.-disabled:focus input:checked ~ .checkmark,
  .pq-checkbox.-disabled:hover input:checked ~ .checkmark {
    ${(props) =>
      !props.readOnly && 'border: solid 2px rgba(203, 208, 223, 0.3);'}
  }
  .pq-checkbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 8px;
    border: solid #3f6db4;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export const CheckBox = forwardRef<HTMLInputElement, CheckBoxTypes>(
  (props, forwardedRef) => {
    const {
      children,
      className,
      wrapperClassName = '',
      logo,
      caption,
      name,
      refRegister,
      primary,
      disabled,
      backgroundColor,
      readOnly,
      ...rest
    } = props

    const wrapperClasses = classNames(wrapperClassName)
    const classes = classNames(
      'pq-checkbox',
      (primary && '-primary') || '',
      (disabled && '-disabled') || '',
      className || '',
    )
    const captionClasses = classNames(
      'pq-checkbox-title',
      (disabled && '-disabled') || '',
      className || '',
    )

    const combinedRefs = useCombinedRefs(refRegister, forwardedRef)

    return (
      <CheckBoxWrapper
        className={wrapperClasses}
        backgroundColor={backgroundColor}
        disabled={disabled}
        readOnly={readOnly}
        logo={logo}
      >
        <label className={classes} htmlFor={name}>
          {logo && (
            <Image
              className="pq-ml-2"
              src={logo}
              alt="logo"
              width={44}
              height={44}
            />
          )}
          <span className={captionClasses}>{caption}</span>
          <input
            data-cy="form-input-checkbox"
            type="checkbox"
            id={name}
            name={name}
            ref={combinedRefs}
            disabled={disabled}
            readOnly={readOnly}
            {...rest}
          />
          <span className="checkmark" />
          {children}
        </label>
      </CheckBoxWrapper>
    )
  },
)
